import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class GrowthZoneConfigRender extends IntegrationConfigRenderBase {

  protected buildPopUpUrl(): string {
    return `${this.systemInfo.keyUrl}?client_id=${this.props.clientIds[this.props.data.systemName]}&scope=contact_data+offline_access&response_type=code&redirect_uri=${RASA_SELF_SERVICE_API}/integrations/${this.props.data.systemName.toLowerCase()}-oauth-callback&state=${this.encodedCommunityIdentifier}`
  }
}
