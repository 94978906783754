import { FeatureUnavailableComponent } from 'components/feature-unavailable'
import * as Flash from 'components/flash'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { RecordIdType } from 'generic/baseClientEntity'
import { Dataset, DatasetParam } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as GA from 'google-analytics'
import { isEmpty } from 'lodash'
import React from 'react'
import { IntegrationSystems, RasaApiKeySystems } from 'shared/data-layer/integration'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import '../styles.css'
import { ActiveCampaignConfigRender } from './integration/activeCampaign'
import {ConstantContactConfigRender} from './integration/constantContact'
import { DeskeraConfigRender } from './integration/deskera'
import { FontevaConfigRender } from './integration/fonteva'
import { GrowthZoneConfigRender } from './integration/growthZone'
import { HigherLogicConfigRender } from './integration/higherLogic'
import { HigherLogicMagnetMailConfigRender } from './integration/higherLogicMagnetMail'
import { HubSpotConfigRender } from './integration/hubspot'
import { ImisConfigRender } from './integration/imis'
import { InfusionsoftConfigRender } from './integration/infusionsoft'
import { InstagramConfigRender } from './integration/instagram'
import {
  IntegrationConfigRenderBase,
  IntegrationConfigRenderProps,
  ReconnectIntegrationModal } from './integration/integrationConfigRenderBase'
import { KlaviyoConfigRender  } from './integration/klaviyo'
import { LinkedInConfigRender } from './integration/linkedin'
import { MailchimpConfigRender } from './integration/mailchimp'
import { NimbleConfigRender } from './integration/nimble'
import { PocketRssConfigRender } from './integration/pocketRss'
import { RasaApiKeySystemConfigRender } from './integration/rasaApiKeySystem'
import { ReplugConfigRender } from './integration/replug'
import { SalesflareConfigRender } from './integration/salesflare'
import { SalesforceConfigRender } from './integration/salesforce'
import { ShopifyConfigRender } from './integration/shopify'
import { StripeConfigRender } from './integration/stripe'
import { WordPressConfigRender } from './integration/wordPress'
import { YourMembershipConfigRender } from './integration/yourMembership'
import * as Integrations from './integrations'
import * as Utils from './utils'
import { ImpexiumConfigRender } from './integration/impexium'
import { InformzConfigRender } from './integration/informz'
import { SalesForceCloudRender } from './integration/salesforceMarkettingCloud'
import { ZoomConfigRender } from './integration/zoom'

class SettingsIntegrationsConfigComponent
  extends RasaReactComponent<Integrations.SettingsIntegrationsConfigProps,
  Integrations.SettingsIntegrationsConfigState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: Integrations.SettingsIntegrationsConfigProps) {
    super(props, 'communitySystemIntegration')
    this.state = {
      activeCommunity: null,
      clientIds: {},
      hasCognitoIdentifier: false,
      hasFeatureAccess: false,
      isDirty: false,
      isLoading: true,
      isSaving: false,
      isSettingsLoading: true,
      params: {},
      person: null,
      plan: null,
      usageStats: null,
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.params),
      this.sharedStore.getValue(SharedKeys.person),
      this.getAndSetClientIds(),
    ])
      .then(([activeCommunity, params, person, clientIds]) => {
        this.setState({
          activeCommunity,
          clientIds,
          hasCognitoIdentifier: !isEmpty(person.accountInfo.cognito_identifier),
          params,
          person,
          plan: activeCommunity.billingInfo && activeCommunity.billingInfo.currentPlan
            ? activeCommunity.billingInfo.currentPlan : null,
          usageStats: activeCommunity.billingInfo.usageStats,
        }, () => {
          this.initializeRecord()
            .then(() => {
              if (this.state.plan) {
                const url: string = AjaxWrapper.getServerUrl() + `/integrations/communityIntegrations/${this.state.activeCommunity.communityId}`
                AjaxWrapper.ajax(url, HttpMethod.GET, null).then((response) => {
                  this.setState({
                    hasFeatureAccess: this.canViewPage(params, response),
                    isSettingsLoading: false,
                  })
                })
              }
            })
        })
      })
  }

  public render() {
    return (
      <div className="settings-system-outer-wrapper">
        <div className="settings-integrations-config-wrapper">
          <div className="go-back" onClick={() => this.goBack()}>&lt; &nbsp; Go back</div>
          {this.state.isSettingsLoading
            ? <Loading size="32" />
            : ( !this.state.isSettingsLoading && !this.canAccessPage() )
            ?
              <div>
                <h3>Something has gone wrong</h3>
              </div>
            :
              this.state.hasFeatureAccess ?
              <RenderIntegrationConfig {...this.props} {...this.state}
                context={this.context}
                loadRecord={this.thisLoadRecord}
                saveRecord={this.thisSave}
              /> :
              <FeatureUnavailableComponent source={GA.UpgradeSource.Integrations} />
          }
        </div>
      </div>
    )
  }

  private canAccessPage = () => {
    return this.props.data.id || this.props.data.systemName
  }

  private thisSave = (recordId: RecordIdType) => {
    return this.saveRecord(recordId)
  }

  private thisLoadRecord = (communityId: string, recordId: RecordIdType) => {
    return this.loadRecord(communityId, recordId)
  }

  private goBack() {
    this.props.push('/settings/integrations')
  }

  private getAndSetClientIds() {
    const url: string = `${AjaxWrapper.getServerUrl()}/integrations/sso-clients`
    return AjaxWrapper.ajax(url, HttpMethod.GET, null)
  }

  private initializeRecord() {
    if (this.state.params.id) {
      return this.loadRecord(this.state.activeCommunity.communityId, this.state.params.id)
        .then(() => {
          return true
        })
        .catch((err) => {
          let errorMessage = 'Invalid Integration Record'
          if (err.response && err.response.message) {
            errorMessage = err.response.message
          }
          this.context.store.dispatch(Flash.showFlashError(errorMessage))
        })
    } else {
      const params: DatasetParam[] = [
        {
          param: 'name',
          value: this.state.params.systemName,
        },
      ]
      return new Dataset().loadCommunityDataset('integrationSystems', this.state.activeCommunity.communityId, params)
          .then((result) => {
            const systems = result[0]
            if ( systems.length === 1 ) {
              return this.loadRecord(this.state.activeCommunity.communityId, null).then((x) => {
                this.props.propertiesChanged({
                  id: null,
                  is_active: null,
                  community_identifier: this.state.activeCommunity.communityId,
                  key: '',
                  secret: '',
                  token: '',
                  systemMetadata: systems[0].systemMetadata,
                  systemName: this.state.params.systemName,
                  display_name: this.state.params.displayName,
                })
                if (this.state.params.message) {
                  this.context.store.dispatch(Flash.showFlashError(
                    Integrations.notificationMessages[this.state.params.operation]))
                }
                return true
              })
            } else {
              this.context.store.dispatch(Flash.showFlashError(
                Integrations.notificationMessages[SettingsIntegrationsConfig.CreateFailure]))
              return false
            }
        })
    }
  }

  private canViewPage(params, response): boolean {
    // if integration/communitySystem with specified ID is active => user can view page
    if (params.systemName && Utils.isUnrestrictedIntegration(params.systemName)) {
      return true
    }
    const currentConfig = response.filter((i) => (i.community_system_id === Number(params.id)))[0]
    if (Utils.isUnrestrictedIntegration(currentConfig && currentConfig.name)) {
      return true
    }
    if (currentConfig && (currentConfig.is_active || currentConfig.connected)) {
      return true
    } else {
      //else user can only view a system's page if integration limit has not been reached
      return response.length ?
        response.filter((i) => i.is_active === 1).length < this.allowedIntegrationCount() : false
    }
  }

  private allowedIntegrationCount = (): number => {
    return this.state.plan ?
      BillingPlan.getMaximumNumberOfIntegrations(this.state.plan) : 0
  }
}

const RenderIntegrationConfig = (props: IntegrationConfigRenderProps) => {
  if (props.data.systemName === IntegrationSystems.ActiveCampaign) {
    return <ActiveCampaignConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.ConstantContact) {
    return <ConstantContactConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Deskera) {
    return <DeskeraConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Fonteva) {
    return <FontevaConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.GrowthZone) {
    return <GrowthZoneConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.HigherLogic) {
    return <HigherLogicConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.HigherLogicMagnetMail) {
    return <HigherLogicMagnetMailConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Hubspot) {
    return <HubSpotConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Imis) {
    return <ImisConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Infusionsoft) {
    return <InfusionsoftConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Instagram) {
    return <InstagramConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Klaviyo) {
    return <KlaviyoConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.LinkedIn) {
    return <LinkedInConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Mailchimp) {
    return <MailchimpConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Nimble) {
    return <NimbleConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.PocketRss) {
    return <PocketRssConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Replug) {
    return <ReplugConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Salesflare) {
    return <SalesflareConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Shopify) {
    return <ShopifyConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Stripe) {
    return <StripeConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.WordPress) {
    return <WordPressConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.YourMembership) {
    return <YourMembershipConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Impexium) {
    return <ImpexiumConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Informz) {
    return <InformzConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Salesforce) {
    return <SalesforceConfigRender {...props} />
  } else if (RasaApiKeySystems.includes(props.data.systemName)) {
    return <RasaApiKeySystemConfigRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.SalesforceMarketingCloud) {
    return <SalesForceCloudRender {...props} />
  } else if (props.data.systemName === IntegrationSystems.Zoom) {
    return <ZoomConfigRender {...props} />
  } else {
    return <IntegrationConfigRenderBase {...props} />
  }
}

export const SettingsIntegrationsConfig =
  GenericRedux.registerNewComponentWithModals<Integrations.ConfiguredCommunitySystem>(
    SettingsIntegrationsConfigComponent,
    'settings_integrations_config',
    [ReconnectIntegrationModal.key],
    Integrations.emptyCommunitySystem,
  )
